import axios from "axios";
import {
    CreateOrderData,
    EventData,
    Events,
    PayOrderResult,
    ReserveOrderResult,
    OrdersData,
    Seat
} from "@/@types/api";
import {getCurrentUser} from "@/firebase";

function getFireUrl(apiUrl: string) {
    return apiUrl
        .replace('https://', '')
        .replace('http://', '')
        .replace('api.ticketcafe.ru:449/', '')
        .replace('api.ticketcafe.ru/', '')
        .replace(/[/]/g, '-')
        .replace(/[.!:]/g, '')
}

//const ROOT_URL = 'https://api.ticketcafe.ru/widget2/hs/widget/'
//export const API_URL = 'https://api.ticketcafe.ru:449/widget2.dev/hs/widget'
export const API_URL = process.env.VUE_APP_API_URL ?? ''
export const FIRE_URL = getFireUrl(API_URL)

console.log('VUE_APP_API_URL', API_URL)

//const authConfig = {}//{auth: {username: 'backstage', password: 'Fk9843756Bd'}}

// todo gzip apache включить

let userEmail = ''

export const setUserEmail = (email: string) => {
    userEmail = email
}

export async function getEvents(): Promise<Events | undefined> {
    return (await axios.get(`${API_URL}/events?userEmail=${userEmail}`)).data.data
}

export async function getEventReserves(eventId: number): Promise<OrdersData | undefined> {
    return (await axios.get(`${API_URL}/events/${eventId}/reserves?userEmail=${userEmail}`)).data.data
}

export async function getEventOnlineOrders(eventId: number): Promise<OrdersData | undefined> {
    return (await axios.get(`${API_URL}/events/${eventId}/online-orders?userEmail=${userEmail}`)).data.data
}

export async function getEventOfflineOrders(eventId: number): Promise<OrdersData | undefined> {
    return (await axios.get(`${API_URL}/events/${eventId}/offline-orders?userEmail=${userEmail}`)).data.data
}


export async function getEvent(eventId: string): Promise<EventData | undefined> {
    if (!eventId) return undefined;
    return (await axios.get(`${API_URL}/events/${eventId}?userEmail=${userEmail}`)).data.data
}

export async function createOnlineOrder(data: CreateOrderData, sbp = false): Promise<PayOrderResult> {

    return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/online-orders?userEmail=${userEmail}&sbp=${sbp}`, data)
                .then(result => {
                    if (result.data.data.payUrl)
                        resolve(result.data.data)
                    else
                        reject(undefined)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}

export async function createOfflineOrder(data: CreateOrderData): Promise<{ orderId: string }> {

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/offline-orders?userEmail=${userEmail}`, data, {})
            .then(result => {
                if (result.data.data.orderId)
                    resolve(result.data.data)
                else
                    reject(null)
            })
            .catch(err => {
                reject(err)
            })
    })
    // try {
    //     const result = (await axios.post(`${API_URL}/offline-orders?userEmail=${userEmail}`, data)).data.data as {orderId: string}
    //
    //     if (result?.orderId)
    //         return result
    //     else
    //         throw 'Invalid ReserveOrderResult'
    //
    // } catch (e) {
    //     throw 'Invalid ReserveOrderResult'
    // }

}


export async function createReserve(data: CreateOrderData): Promise<ReserveOrderResult> {

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/reserves?userEmail=${userEmail}`, data)
            .then(result => {
                if (result.data.data.orderId)
                    resolve(result.data.data)
                else
                    reject(undefined)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export async function cancelReserve(id: string): Promise<boolean> {

    try {
        const result = (await axios.post(`${API_URL}/reserves/${id}/cancel?userEmail=${userEmail}`)).data.data as boolean | undefined
        if (result)
            return true
        else {
            return false
        }
    } catch (e) {
        return false
    }
}

export async function sellReserve(id: string): Promise<string | undefined> {

    try {
        const result = (await axios.post(`${API_URL}/reserves/${id}/sell?userEmail=${userEmail}`)).data.data as string | undefined
        if (result)
            return result
        else
            return undefined

    } catch (e) {
        return undefined
    }
}

export async function OnlineOrderSendTickets(id: string): Promise<{ data?: string, error?: string } | undefined> {

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/online-orders/${id}/send-tickets?userEmail=${userEmail}`)
            .then(result => {
                resolve(result.data)

            })
            .catch(err => {
                resolve(err.response.data)
            })
    })

}

// todo дубль кода online и offline
export async function OfflineOrderSendTickets(id: string): Promise<{ data?: string, error?: string } | undefined> {

    return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/offline-orders/${id}/send-tickets?userEmail=${userEmail}`)
                .then(result => {
                    console.log(result)
                    resolve(result.data)

                })
                .catch(err => {
                        resolve(err.response.data)
                    }
                )
        }
    )


    // try {
    //     const result = (await axios.post(`${API_URL}/offline-orders/${id}/send-tickets?userEmail=${userEmail}`)).data.data as boolean | undefined
    //     if (result)
    //         return result
    //     else
    //         return undefined
    //
    // } catch (e) {
    //     return undefined
    // }
}


// export function cancelOrder(orderId) {
//      //axios.get(`${ROOT_URL}order_cancel`, orderId)
// }
