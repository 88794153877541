

import SvgMap from "@/components/Map.vue";
import {Component, Vue} from "vue-facing-decorator";
import {getCurrentUser, RTDB} from "@/firebase";
import {User} from "firebase/auth";

@Component({
  components: {
    SvgMap
  }
})
export default class App extends Vue {

  userData: User | null = null

  dialog = false
  dialogSuccess = true
  dialogTitle = ''
  dialogText = ''

  async created() {

    console.log('TicketCafe APP created')
    //console.log('process.env=', process.env)

    this.userData = await getCurrentUser()

    if (!this.userData || !this.userData?.email)
      this.$router.push('/auth')

    if (!process.env.VUE_APP_API_URL)
      console.error('env.VUE_APP_API_URL not defined!')


  }

  onMsg(success, title = '', text = '') {

    this.dialogSuccess = success
    this.dialogTitle = title || (success ? 'Успешно' : 'Ошибка')
    this.dialogText = text
    this.dialog = true

  }

}

