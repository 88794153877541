export function redirect(url) {
    window.location.href = url
}

export function formatMoney(amount: number, digits = 0) {
    // Преобразуем число в строку и разделяем целую и десятичную части
    const parts = amount.toFixed(digits).toString().split('.');

    // Форматируем целую часть, добавляя разделители тысяч
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');

    // Возвращаем отформатированную строку
    return parts.join('.') + ' ₽';
}
