
import {Component, Emit, Prop, Vue} from "vue-facing-decorator";
import {
  cancelReserve,
  getEventOfflineOrders,
  getEventOnlineOrders,
  getEventReserves, OfflineOrderSendTickets,
  OnlineOrderSendTickets,
  sellReserve
} from "@/api";
import {OrderData, OrdersData} from "@/@types/api";
import {formatMoney} from "@/utils";

@Component
export default class EventOrders extends Vue {

  @Prop
  eventId?: number

  @Prop
  orderType: 'reserve' | 'online' | 'offline' = 'reserve'

  ordersData?: OrdersData = undefined

  selectedOrder?: OrderData = undefined

  dialogSelected = false
  dialogConfirm = false
  dialogConfirmTitle = ''
  dialogConfirmFunc?: () => void

  openDialogConfirm(title: string, func: () => void) {
    this.dialogConfirmTitle = title
    this.dialogConfirm = true
    this.dialogConfirmFunc = func

  }

  closeDialogConfirm() {
    this.dialogConfirm = false
    this.dialogConfirmFunc = undefined
  }

  onConfirm() {
    if (this.dialogConfirmFunc) this.dialogConfirmFunc()
    this.dialogConfirm = false
  }

  pending = false

  formatMoney = formatMoney

  async created() {

    console.log('EventOrders created')

    this.loadData()

  }

  sortByOrderId(orders: OrdersData): OrderData[] {
    return Object.values(orders).sort((a,b)=> a.id < b.id ? 1 : -1)
  }

  async loadData() {
    this.pending = true

    if (this.eventId)
      if (this.orderType === 'reserve')
        this.ordersData = await getEventReserves(this.eventId)
      else if (this.orderType === 'offline')
        this.ordersData = await getEventOfflineOrders(this.eventId)
      else if (this.orderType === 'online')
        this.ordersData = await getEventOnlineOrders(this.eventId)

    this.pending = false
  }

  onClick(order: OrderData) {

    this.selectedOrder = order
    this.dialogSelected = true
  }

  async deleteReserve() {

    this.openDialogConfirm('Аннулировать?', async () => {

      this.pending = true
      if (this.selectedOrder)
        if (await cancelReserve(this.selectedOrder.id))
          this.dialogSelected = false

      this.loadData()
    })
    //else
  }

  async sellReserve() {
    this.openDialogConfirm('Продать?', async () => {
      this.pending = true
      if (this.selectedOrder)
        if (await sellReserve(this.selectedOrder.id))
          this.dialogSelected = false

      this.loadData()
    })

  }

  async sendTickets() {

    this.openDialogConfirm(`Отправить билеты повторно на ${this.selectedOrder?.clientEmail}?`, async () => {
      this.pending = true
      if (this.selectedOrder) {

        const result = this.orderType === 'online'
            ? await OnlineOrderSendTickets(this.selectedOrder.id)
            : await OfflineOrderSendTickets(this.selectedOrder.id)

        if (result?.error)
          this.$emit('msg', false, 'Ошибка', result.error)
        else
          this.$emit('msg', true)

      }

      this.dialogSelected = false

      this.loadData()
    })

  }

}
