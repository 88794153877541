
import {Component, Prop, Vue} from 'vue-facing-decorator';
import {getEvents} from "@/api";
import {Events} from "@/@types/api";
import {getCurrentUser} from "@/firebase";
import {User} from "firebase/auth";

@Component
export default class Success extends Vue {

  userData: User | null = null

  events: Events | undefined

  async created() {
    this.userData = await getCurrentUser()

    this.events = await getEvents()

  }


}
