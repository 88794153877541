
import {Component, Emit, Prop, Vue} from 'vue-facing-decorator';
import Map from "@/components/Map.vue";
import EventOrders from "@/components/EventOrders.vue";
import {User} from "firebase/auth";
import {getCurrentUser} from "@/firebase";

@Component({
  components: {EventOrders, Map}
})
export default class Event extends Vue {
  tab = 1

  userData: User | null = null

  msg(...args){
    console.log('args', args)
    this.$emit('msg', ...args)
  }

  async created(){
    this.userData = await getCurrentUser()
  }

}
