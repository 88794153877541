import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Event from '../views/Event.vue'
import Success from '../views/Success.vue'
import Fail from '../views/Fail.vue'
import QR from '../views/QR.vue'
import Auth from '../views/Auth.vue'
import Events from '../views/Events.vue'
import {RTDB} from "@/firebase";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home
  // },
  {
    path: '/event/:eventId',
    name: 'event',
    component: Event
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/',
    name: 'events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: Events
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/fail',
    name: 'fail',
    component: Fail
  },
  {
    path: '/qr',
    name: 'qr',
    component: QR
  }
]

const router = createRouter({
  //history: createWebHashHistory(), // спользует # в пути , и сбербанк не может перенаправить на такой url клиента
  history: createWebHistory(), // не спользует # в пути
  routes
})

// router.beforeEach(async (to, from, next) => {
//  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   console.log('RTDB.auth.currentUser',RTDB.auth.currentUser)
//   if (!RTDB.auth.currentUser && to.name != 'auth'){
//     next('/auth')
//   }else{
//     next()
//   }
// });

export default router
