


import 'firebaseui/dist/firebaseui.css'

import {Component, Vue} from "vue-facing-decorator";
import {RTDB} from "@/firebase";


@Component({
  components: {}
})
export default class TCEventMap extends Vue {

  mounted() {
    RTDB.firebaseUI.start('#firebaseui-auth-container', RTDB.uiConfig)
  }

}

