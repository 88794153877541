import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi} from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import {Vue} from "vue-facing-decorator";
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

const app = createApp(App)

Vue.prototype.$toMoney = a => a

// Преобразуем число в строку и разделяем целую и десятичную части
app.config.globalProperties.$toMoney =  (amount, digits = 0) => {
    const parts = amount.toFixed(digits).toString().split('.');
    // Форматируем целую часть, добавляя разделители тысяч
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
    // Возвращаем отформатированную строку
    return parts.join('.') + ' ₽';
}

Sentry.init({
    app,
    dsn: "https://9aedaa23d8d74829bc4271193a9472ad@o4505566600822784.ingest.sentry.io/4505566603182080",
    integrations: [
        new CaptureConsoleIntegration({
            levels: ['error']
        }),
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https:admin.ticketcafe.ru"],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),

        }),
        new Sentry.Replay(),
    ],
    logErrors: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#ea5f5a', //'#d39c23', // #FF6E40
                    secondary: '#90A4AE'
                }
            },
        },
    },
    icons: {
        defaultSet: 'mdi',  // даже при дефолтном сете нужно или установить npm install @mdi/font или прописать CDN
        aliases,
        sets: {
            mdi,
        },
    },
})

createApp(App)
    .use(vuetify)
    .use(router)
    .mount('#app')

//setTimeout(()=>{window['yUndefinedFunction()']();}, 3000)
