
import {Component, Prop, Vue} from 'vue-facing-decorator';
import * as API from "@/api";
import {EntryTicket, EventData, FREventResult, Seat} from "@/@types/api";
import SvgPanZoom from "svg-pan-zoom";
import {getCurrentUser, RTDB} from "@/firebase";
import firebase from "firebase/compat";
import {User} from "firebase/auth";
import {createOnlineOrder} from "@/api";

@Component({
  components: {}
})
export default class TCEventMap extends Vue {

  user: User | null = null

  orderType: 'reserve' | 'online' | 'offline' | 'sbp' = 'reserve'
  orderComment = ''

  discount = 0

  selectedLimit = 10

  async createOrder() {

    this.step = 4

    //const userEmail = this.user?.email ?? ''

    const data = {
      eventId: this.eventId,
      seats: this.selected.seats,
      entryTickets: this.selected.entry,
      name: this.client.name,
      tel: this.client.tel.trim() ? "7" + this.client.tel.trim() : "",
      email: this.client.email,
      sum: this.getTotalSum(),
      comment: this.orderComment,
      discount: this.discount,
    }

    if (this.orderType === 'reserve') {

      // const orderData = await API.createReserveOrder(userEmail, data)
      // if (orderData) {
      //   console.log('orderData', orderData)
      //   this.$router.push('/success?orderId=' + orderData.orderId)//this.step = 7
      //
      // } else
      //   setTimeout(()=>this.$router.push('/fail'), 400)


      API.createReserve(data)
          .then(orderData => {
            this.$router.push('/success?orderId=' + orderData.orderId)//this.step = 7
          })
          .catch((e) => {
            //console.error(e)
            this.step = 7
          })
    } else if (this.orderType === 'online') {

      API.createOnlineOrder(data, false)
          .then(orderData => {
            this.$router.push(`/qr?url=${orderData.payUrl}`)
          })
          .catch((e) => {
            console.error(e)
            this.step = 7
          })

    } else if (this.orderType === 'sbp') {

      API.createOnlineOrder(data, true)
          .then(orderData => {
            //this.$router.push(`/qr?url=${orderData.payUrl}`)
            window.location.href = '/qr?url=' + encodeURIComponent(orderData.payUrl)
            //window.location.href = orderData.payUrl
          })
          .catch((e) => {
            console.error(e)
            this.step = 7
          })

    } else if (this.orderType === 'offline') {
      API.createOfflineOrder(data)
          .then(orderData => {
            this.$router.push('/success?orderId=' + orderData.orderId)//this.step = 7
          })
          .catch(e => {
            this.step = 7

          })
    }

  }

  windowReload() {
    // window.parent.location.reload()
  }

  dialogSellerInfo = false

  getSelectedSeatsData(): Seat[] {
    return Object.values(this.eventData?.seats ?? {}).filter(s => this.selected.seats.includes(s.id))
  }

  getTotalSum() {
    const sum = this.getSelectedSeatsData().reduce((sum, v) => sum + v.price, 0)
        + this.selected.entry.reduce((sum, v) => sum + v.price, 0)

    console.log(Math.round(sum * this.discount * 0.01))

    return sum - Math.round(sum * this.discount * 0.01)
  }

  getTotalCount() {
    return this.getSelectedSeatsData().length + this.selected.entry.length
  }

  discountChange() {

    if (this.discount < 0 || this.discount > 100)
      this.discount = 0
  }

  async onSubmitClientForm() {

    const {valid} = await (this.$refs.clientForm as unknown as any).validate()

    if (valid)
      this.step = 3

  }

  client = {
    name: '',
    email: '',
    tel: '',
    error: true
  }

  nameRules = [
    value => {
      this.client.error = !value

      if (!this.client.error) return true

      return 'Имя обязательно'
    },
    value => {
      this.client.error = !/^[a-zA-Zа-яА-Я\s']+$/.test(value)

      if (!this.client.error) return true

      return 'Некорректные символы'
    },
    value => {
      this.client.error = !(value?.length <= 32)

      if (!this.client.error) return true

      return 'Имя слишком длинное'
    }
  ]

  emailRules = [
    value => {
      this.client.error = !value

      if (!this.client.error) return true

      return 'E-mail обязательный'
    },
    value => {
      this.client.error = !/.+@.+\..+/.test(value)

      if (!this.client.error) return true

      return 'E-mail некорректный'
    },
  ]

  telRules = [
    value => {
      //if (/^\d{11}$/.test(value)) return true
      this.client.error = !/^\d+$/.test(value)

      if (!this.client.error) return true
      return 'Некорректный телефон'
    },

  ]

  @Prop
  eventId = ''

  svgHtml = ''

  isMapPanned = false

  step = 1

  hoveredCardSeatId: number | undefined = undefined

  selected: {
    seats: number[]
    entry: { id: number, segmentId: number, segmentName: string, price: number }[]
  } = {
    seats: [],
    entry: []
  }

  seatOver: Seat | undefined = undefined

  seatTooltipStyle = {
    top: '',
    left: ''
  }

  eventData: EventData | undefined = undefined

  getSortedPrices(): number[] {
    return [...new Set(Object.values(this.eventData?.seats ?? {}).map(seat => seat.price).sort((a, b) => b - a))]
  }

  onHoverSelected(seatId: number) {
    this.hoveredCardSeatId = seatId
    document.getElementById(seatId.toString())?.classList.add('seat_hover')
  }

  onPriceHover(price) {
    // this.eventData?.seats?.forEach(seat => {
    //   if (seat.price === price)
    //     document.getElementById(seat.seatId.toString())?.classList.add('seat_hover')
    // })
  }

  onPriceLeave(price) {
    // this.eventData?.seats?.forEach(seat => {
    //   //if (seat.price === price) {
    //   document.getElementById(seat.seatId.toString())?.classList.remove('seat_hover')
    //   // }
    //
    // })
  }

  onLeaveSelected() {
    if (this.hoveredCardSeatId)
      document.getElementById(this.hoveredCardSeatId.toString())?.classList.remove('seat_hover')
    this.hoveredCardSeatId = undefined
  }

  // для определения когда в dom загрузился maps
  firstUpdate = true

  updated() {

    // Это инициализация когда SVG загрузился
    if (this.firstUpdate && document.getElementById('svgMapElement')) {
      this.firstUpdate = false
      this.initPanZoom()
      this.renderSeats()
    }
  }

  // loadEventData() {
  //   API.getEvent(this.eventId)
  //       .then(eventData => {
  //
  //         if (eventData) {
  //
  //           this.eventData = eventData as EventData
  //
  //           // for (const seat of this.eventData.seats ?? []) {
  //           //
  //           //   //  костыль, если забыли в 1с назначить описание к месту
  //           //   seat.seatDesc = seat.seatDesc || (`${seat.segmentName} стол ${seat.rowNumber}`)
  //           //
  //           //   //console.log(document.getElementById('svgMapElement'))
  //           //
  //           // }
  //           // this.eventData.seats = [...this.eventData.seats ?? []]
  //
  //         } else {
  //           console.error('eventData result is undefined')
  //         }
  //
  //         //this.initPanZoom()
  //
  //         //this.eventData?.seats?.forEach(seat => document.getElementById(seat.seatId.toString())?.classList.add("seat_free"))
  //         //this.$forceUpdate()
  //
  //       })
  //       .catch(e => {
  //         console.error(e)
  //         this.$router.push('/fail')
  //       })
  // }

  renderSeats() {
    if (!this.svgMapElement || !this.eventData) {
      console.error(new Error('Схема не готова или нет данных'))
      return;
    }

    //const elements = Array.from(this.svgMapElement.getElementsByClassName('seat') as HTMLCollectionOf<HTMLElement>) //as HTMLElement[])
    const elements = this.svgMapElement.querySelectorAll<HTMLElement>('.seat')

    if (this.eventData.seats)
      for (const element of elements) {

        // удаляем стиль ценовой группы
        element.classList.forEach(name => {
          if (name.includes('price-')) element.classList.remove(name)
        })

        const seat = this.eventData.seats[element.id]

        //console.log('seat ',element.id, !!seat)

        if (seat) {

          element.classList.add('seat_free');

          // element.style.fill = this.colorPalette.get(seat.price) ?? 'yellow';
          // element.style.stroke = this.colorPalette.get(seat.price) ?? 'yellow';
          element.classList.add('price-' + (this.getSortedPrices().indexOf(seat.price) + 1))

        }
        // Место не в продаже. Оставляем только класс seat
        else {
          element.classList.remove('seat_free')

          // element.style.removeProperty('fill')
          // element.style.removeProperty('stroke')

          // если место было выбрано - снимаем

          this.removeSeatFromSelected(Number(element.id))

        }

      }

    // Object.values(this.eventData.seats)?.forEach(seat => {
    //
    //   const element = document.getElementById(seat.id.toString())
    //
    //   if (element) {
    //     element.classList.add('seat_free');
    //     element.style.fill = this.colorPalette.get(seat.price) ?? 'white';
    //     element.style.stroke = this.colorPalette.get(seat.price) ?? 'white';
    //
    //   } else {
    //     // в 1с с запасом заведено, поэтому часто срабатывает
    //     //console.error(new Error('не найден элемент места по id '+seat.seatId))
    //     return;
    //   }
    //
    // })

  }

  zoom(zoomIn: boolean) {

    if (this.svgMapElement)
      if (zoomIn)
        SvgPanZoom(this.svgMapElement).zoomIn()
      else
        SvgPanZoom(this.svgMapElement).zoomOut()

  }

  formatMoney(amount, digits = 0) {
    // Преобразуем число в строку и разделяем целую и десятичную части
    let parts = amount.toFixed(digits).toString().split('.');

    // Форматируем целую часть, добавляя разделители тысяч
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');

    // Возвращаем отформатированную строку
    return parts.join('.') + ' ₽';
  }

  svgMapElement: HTMLElement | null = null

  initPanZoom() {

    const initSvgTooltip = (svgMapElement) => {

      // Функция для обработки события наведения на элемент
      const showTooltip = (event) => {

        // console.log(event)

        const svgElement = event.target as HTMLElement
        //svgElement.classList.add('seat_over')

        // Позиция подсказки рядом с элементом
        // const svgRect = svgElement.getBoundingClientRect()
        //  this.seatTooltipStyle.top = event.top + 2 + 'px'
        this.seatTooltipStyle.top = event.layerY + 20 + 'px'
        // this.seatTooltipStyle.left = svgRect.left + svgRect.width + 2 + 'px'
        this.seatTooltipStyle.left = event.layerX + 10 + 'px'

        // Задаем место на которое навели - подсказка появится
        //this.seatOver = this.eventData?.seats?.find(v => v.seatId === Number(svgElement.id))
        this.seatOver = (this.eventData?.seats || {})[svgElement.id]

      }

      // Функция для обработки события ухода курсора с элемента
      const hideTooltip = (event) => {
        //const svgElement = event.target as HTMLElement
        //svgElement.classList.remove('seat_over')
        this.seatOver = undefined
        //tooltip.style.display = 'none'
      }

      const svgElements = svgMapElement.getElementsByClassName('seat')
      for (let i = 0; i < svgElements.length; i++) {
        const svgElement = svgElements[i]
        svgElement.addEventListener('mouseenter', showTooltip)
        svgElement.addEventListener('mouseleave', hideTooltip)
      }

    }

    this.svgMapElement = document.getElementById('svgMapElement')

    if (this.svgMapElement) {

      SvgPanZoom(this.svgMapElement, {
        // viewportSelector: '.seatScheme',
        mouseWheelZoomEnabled: false,
        dblClickZoomEnabled: false,
        center: true,
        fit: true,
        controlIconsEnabled: false,
        zoomScaleSensitivity: 1,
        maxZoom: 3,
        minZoom: 1,
        preventMouseEventsDefault: true
      })

      initSvgTooltip(this.svgMapElement)
    }

  }


  async loadSVG(name: string) {

    // todo Учесть тут может быть исключение!

    // if (name === 'https://web.ticketcafe.ru/static/2.svg')
    //   name = 'shore'

    //
    // const response = window.origin.includes('//localhost')
    //     ? await fetch(`/maps/${(name.replace('.svg', ''))}.svg`) // todo заменить на env variable
    //
    //     // ВАЖНО! если домены разные то CROS заблочит!
    //     : await fetch(`https://widget.ticketcafe.ru/maps/${(name.replace('.svg', ''))}.svg`) // todo заменить на env variable

    const response = await fetch(`/maps/${(name.replace('.svg', ''))}.svg`)

    if (response.ok) {
      this.svgHtml = await response.text()
      // рендер сработает в событии update this.renderSeats()

    } else {
      console.error(response)
      this.$router.push('/fail')
    }

  }

  async created() {

    this.user = await getCurrentUser()

    this.client.email = this.user?.email ?? ''

    if (!this.eventId) {
      console.error('eventId is undefined on created hook')
      return;
    }

    // Вариант прямой

    this.eventData = await API.getEvent(this.eventId)
    if (!this.eventData) {
      console.error('eventData отсутствует по этому мероприятию')
      this.step = 7
      return;
    }

    if (this.eventData?.svgMapName)
      this.loadSVG(this.eventData.svgMapName)
          .catch(e => console.error(e))
    else {
      console.error('svgMapName не определена')
      this.step = 7
      return;
    }


    // Вариант через FR но как его своевременно обновлять?
    //let firstResponse = true;
    // RTDB.onValue(RTDB.getRef(`/events/${this.eventId}`), async (snap) => {
    //   const result = snap.val() as FREventResult | undefined
    //
    //   // if (!result?.data || !result?.timestamp) {
    //   //   API.getEvent(this.eventId)
    //   //   return;
    //   // }
    //
    //   // ПЕРВОЕ ПОЛУЧЕНИЕ ДАННЫХ
    //   if (firstResponse) {
    //     firstResponse = false
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //
    //       const cacheTimeout = ~~(Date.now() * 0.001) - result.timestamp
    //       // console.log(~~(Date.now() * 0.001))
    //       // console.log(result.timestamp)
    //       console.log('EventData FR cache timeout:', cacheTimeout)
    //
    //       // todo если схема очень протухла а прямой запрос не удался - что делать?
    //
    //       if (cacheTimeout > 60 * 2) {
    //         console.log('Old cache, call api...')
    //         API.getEvent(this.eventId)
    //             .catch(e => console.error(e))
    //       }
    //
    //     } else
    //       this.eventData = await API.getEvent(this.eventId)
    //
    //     // Данные не получены не из FR не из Сервера
    //     if (!this.eventData) {
    //       console.error('eventData отсутствует по этому мероприятию')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //     if (this.eventData?.svgMapName)
    //       this.loadSVG(this.eventData.svgMapName)
    //           .catch(e => console.error(e))
    //     else {
    //       console.error('svgMapName не определена')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //
    //   } else {
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //       this.renderSeats()
    //     } else {
    //       console.error('Получены пустые данные event')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //   }
    //
    //
    // })

  }

  async mounted() {

    console.log('Map Mounted')

  }

  addSeatToSelected(id: number) {
    if (this.getTotalCount() >= this.selectedLimit) return;

    this.selected.seats.push(id)

    const element = document.getElementById(id.toString())
    if (element) {
      //element.classList.remove("seat_free")
      element.classList.add("seat_selected")
    }

    const elementRow = document.getElementById(id.toString().substring(0, 6))
    if (elementRow) {
      //element.classList.remove("seat_free")
      elementRow.classList.add("row_selected")
    }

    this.selected.seats.reverse()

    //this.selectedSeats = [...this.selectedSeats]
  }

  addEntryToSelected(entry: EntryTicket) {
    if (this.getTotalCount() < this.selectedLimit)
      this.selected.entry.push({
        id: entry.id, segmentId: entry.segmentId, segmentName: entry.segmentName, price: entry.price
      })
  }

  // todo добавить фильтр  entry.price
  delEntryToSelected(id: number) {
    const element = this.selected.entry.find(entry => entry.id === id)
    if (element != undefined)
      this.selected.entry.splice(this.selected.entry.indexOf(element), 1)
  }

  getEntrySelectedCount(segmentId: number, price: number) {
    return this.selected.entry.filter(entry => entry.segmentId === segmentId && entry.price === price).length
  }

  removeSeatFromSelected(id: number) {

    if (this.selected.seats.includes(id))
      this.selected.seats.splice(this.selected.seats.indexOf(id), 1)

    const element = document.getElementById(id.toString())
    if (element) {
      //element.classList.add("seat_free") // todo когда схема будет обновляться в онлайн, тут проверять может не свободно будет
      element.classList.remove("seat_selected")
      element.classList.remove("seat_hover")
    }

  }

  onClickSvgMap(event) {

    if (!this.svgMapElement || !this.eventData?.seats) return;

    // // Для мобилой всегда увеличивает если схема мелка
    // if (this.$vuetify.display.mobile && SvgPanZoom(this.svgMapElement).getZoom() <= 1.1) {
    //   this.zoom(true)
    //   return;
    // }


    // Если элемент НЕ место
    if (!event.target.classList.contains('seat')) return;


    //const seat = this.eventData?.seats?.find(seat => seat.id === Number(event.target.id))
    const seat = this.eventData.seats[event.target.id ?? '']

    // Если место в продаже (свободно)
    if (seat)
        // Если не выбрано -> выбираем
      if (!this.selected.seats.includes(seat.id))
        this.addSeatToSelected(seat.id)
      else
        this.removeSeatFromSelected(seat.id)

    this.$forceUpdate()

  }

  toStep1() {

    this.step = 1

  }

  toStep2() {
    this.step = 2
  }

}

